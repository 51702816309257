import React, { useState, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from "axios";
import Fancybox from "components/_partials/Fancybox";
import 'react-lazy-load-image-component/src/effects/blur.css';
const Gallery = (props: any) => {

    const api_domain: string = "https://meblewojtas.pl";
    const gallery_id = props.match.params.id;

    const [gallery, setGallery] = useState<any>([]);
    const [galleryName, setGalleryName] = useState<string>('');
    const [images, setImages] = useState<Object>({});

    const fetchGalleryIdCall = () => {
        let apiReturn = axios.get(api_domain + "/meblewojtas/api/collections/get/Gallery?filter[_id]=" + gallery_id + "&token=f7edd2e09e8bc0678ef2ea908889d3")
            .then(function (response) {
                setGallery(response.data.entries);
            })
            .catch(function (error) {
                console.log(error);
            });
        return apiReturn;
    };

    useEffect(() => {
        fetchGalleryIdCall();
    }, [gallery_id])

    useEffect(() => {
        if (gallery) {
            gallery.map((value: any) => {
                setGalleryName(value.gallery_name);
                setImages(value.Images);
                document.title = String(value.gallery_name) + ' - Meble Wojtas';
            })
        }
    }, [gallery])

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    return (
        <>
            <div className="subpage">
                <div className="container">
                    <div className="subpage__heading"></div>
                    <div className="subpage__content">
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 mb-4">
                                        <h1>{galleryName}</h1>
                                    </div>
                                </div>
                            </div>
                            {
                                Object.values(images).map((value: any, key: any) => [
                                    <div key={key} className="col-lg-6 col-12 mb-4">
                                        <Fancybox options={{ infinite: false }}>
                                            <LazyLoadImage effect="blur" data-fancybox="i" data-src={api_domain + value.path} src={api_domain + value.path} />
                                        </Fancybox>
                                    </div>
                                ])
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact__top"></div>
        </>
    )
}

export default Gallery;