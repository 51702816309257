import React from "react";
import logoFooter from "assets/logo/logo_footer.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faMailBulk, faLocationArrow, faClock } from '@fortawesome/free-solid-svg-icons'

const Footer = () => {
    return (
        <>
            <footer className="mt-auto">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-12 my-auto text-lg-start text-center">
                            <img width="150" loading="lazy" src={logoFooter} alt="" />
                        </div>
                        <div className="col-lg-7 col-12 me-lg-auto my-auto mt-4 mt-lg-0">
                            <div className="row">
                                <div className="col-lg-6 col-12 p-lg-1 p-0 mb-2 mb-lg-0 mt-lg-0 text-lg-start text-center d-flex justify-content-lg-end justify-content-center">
                                    <span><FontAwesomeIcon icon={faPhone} /> <a href="tel:+48 728 472 740">+48 728 472 740</a></span>
                                </div>
                                <div className="col-lg-6 col-12 p-lg-1 p-0 mb-2 mb-lg-0 text-lg-start text-center d-flex justify-content-lg-end justify-content-center">
                                    <span><FontAwesomeIcon icon={faMailBulk} /> <a href="mailto:biuro@meblewojtas.pl">biuro@meblewojtas.pl</a></span>
                                </div>
                                <div className="col-lg-6 col-12 p-lg-1 p-0 mb-2 mb-lg-0 text-lg-start text-center d-flex justify-content-lg-end justify-content-center">
                                    <span><FontAwesomeIcon icon={faLocationArrow} /> <a>ul. Legnicka 10a, 59-830 Olszyna</a></span>
                                </div>
                                <div className="col-lg-6 col-12 p-lg-1 p-0 mb-2 mb-lg-0 text-lg-start text-center d-flex justify-content-lg-end justify-content-center">
                                    <span><FontAwesomeIcon icon={faClock} /> <a>Czynne 24 godziny na dobę</a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;